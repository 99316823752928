import React from "react";

import { chickenRecipes } from "../data/chickenRecipes";
import { hamRecipes } from "../data/hamRecipes";
import { turkeyRecipes } from "../data/turkeyRecipes";
import Instructions from "../components/InstructionsCard/InstructionsCard";
import Layout from "../components/Layout";
import { Row, Col } from "react-bootstrap";
import RecipeGrid from "../components/RecipeGrid/RecipeGrid";
import ProductCard from "../components/Productcard/ProductCard";

import _ from "lodash";
import { turkeyProducts } from "../data/turkey/turkeyProduct";
import { hamProducts } from "../data/ham/hamProduct";
import { chickenProducts } from "../data/chicken/chickenProduct";

import "./recipe.scss";
const RecipePage = (props) => {
  const { pageContext } = props;

  const name = pageContext?.name ?? "";

  // all recipes
  const allData = [...chickenRecipes, ...hamRecipes, ...turkeyRecipes];

  //retrieve selected recipe
  const recipe = _.filter(allData, (obj) => {
    return _.includes(obj.Link, `recipes/${name}`);
  })[0];

  var {
    recipeName,
    heroImage,
    recipeImageAlt,
    recipeDescription,
    recipeSteps,
    recipeIngredients,
    filterProductType,
  } = recipe;

  //all products
  const productData = [...turkeyProducts, ...hamProducts, ...chickenProducts];
  const products = _.filter(productData, (obj) => {
    return _.includes(filterProductType, obj.recipeName);
  });

  let recipes = [];

  //new recipes without current recipe
  const newData = allData.filter(function (rec) {
    return rec !== recipe;
  });

  for (let i = 0; i < products.length; i++) {
    recipes = _.filter(newData, (ob) => {
      return _.includes(ob.filterProductType, products[i].recipeName);
    });
  }

  return (
    <Layout title={recipeName} description="">
      <section>
        <figure>
          <img src={heroImage} alt={recipeImageAlt} />
        </figure>
      </section>
      <section class="row">
        <div class="inner">
          <h1>{recipeName}</h1>
        </div>
      </section>
      <section class="row">
        <div class="inner">
          <p>
            <big>{recipeDescription}</big>
          </p>
        </div>
      </section>

      <Row as={"section"} md={2} sd={1}>
        <Instructions
          ingredients={recipeIngredients}
          steps={recipeSteps}
          pad="true"
          fromRecipe={true}
        />
      </Row>

      <section class="row">
        <div class="inner centered">
          <div class="Made-With">MADE WITH</div>
        </div>
      </section>

      <Row className="inner pad-top">
        {products.map((element, index) => {
          return (
            <Col xs={12} xl={4} sm={6} lg={4} md={4}>
              <ProductCard
                Link={`/${element.type.toLowerCase()}/` + element.path}
                ProductName={element.recipeName}
                ProductLargeImage={element.image}
                ProductMobileImage={element.mobileImage}
                ProductImageAlt={element.recipeName}
              />
            </Col>
          );
        })}
      </Row>

      <div>
        <RecipeGrid
          filteredCards={recipes}
          text={"OTHER RECIPES YOU MAY LIKE, USING THIS PRODUCT"}
        />
      </div>
    </Layout>
  );
};

export default RecipePage;
